import Papa from "papaparse";

const parseCSV = <T>(pathToCSV: string): Promise<T[]> => {
  return new Promise((resolve, reject) => {
    const timeout = setTimeout(() => {
      reject(new Error("CSV parsing timed out"));
    }, 2000);

    Papa.parse<T>(pathToCSV, {
      header: true,
      skipEmptyLines: true,
      delimiter: ",",
      complete: ({ errors, data }) => {
        clearTimeout(timeout);

        const [error] = errors;
        if (error) {
          reject(error);
        } else {
          console.log({ data });
          resolve(data);
        }
      },
    });
  });
};

export { parseCSV };
