export interface EternalLinkProps {
  href: string;
  children: React.ReactNode;
  className?: string;
  onPressItem?: () => void;
}

function ExternalLink({
  href,
  children,
  className,
  onPressItem,
}: EternalLinkProps) {
  return (
    <a href={href} target="_blank" className={className} onClick={onPressItem}>
      {children}
    </a>
  );
}

export interface MailtoProps {
  email: string;
  children: React.ReactNode;
  className?: string;
}

function Mailto({ email, children, className }: MailtoProps) {
  return (
    <a href={`mailto:${email}`} className={className}>
      {children}
    </a>
  );
}

function Paragraph({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string;
}) {
  return <p className={className}>{children}</p>;
}

export { ExternalLink, Mailto, Paragraph };
