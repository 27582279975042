import classNames from "classnames";

import { ROUTES, HEADER_HEIGHT } from "src/constants";
import Officers from "src/components/officers";
import Committees from "src/components/committees";
import Chapters from "src/components/chapters";
import logoSrc from "src/assets/1812_logo_final-a.png";
import { ExternalLink, Paragraph } from "src/components/common";
import { useBreakpoints } from "src/hooks/common";
import More from "src/components/more";
import NavList from "src/components/nav-list";
import { Container, Main, Nav, Aside } from "src/components/layout";
import Supplements from "src/components/supplements";
import MobileNavList from "./mobile-nav-list";

function Home() {
  const { isSmallDevice, isMediumDevice } = useBreakpoints();

  const compact = isSmallDevice || isMediumDevice;

  return (
    <Container compact={compact}>
      <Nav
        height={HEADER_HEIGHT}
        className={isSmallDevice ? "justify-start" : "justify-center"}
      >
        {isSmallDevice ? <MobileNavList /> : <NavList />}
      </Nav>

      {!compact && (
        <Aside>
          <Supplements />
        </Aside>
      )}

      <Main>
        <Section id={ROUTES.HOME.key}>
          {isMediumDevice && <Supplements layout="horizontal" />}

          <Header className="mb-8" />

          <Paragraph className="mb-4 indent-8">
            The National Society, United States Daughters of 1812 was founded in
            1892. We are a volunteer women's service organization dedicated to
            promoting patriotism, preserving and increasing knowledge of the
            history of the American people by the preservation of documents and
            relics, the marking of historic spots, the recording of family
            histories and traditions, the celebration of patriotic
            anniversaries, the teaching and emphasizing of the heroic deeds of
            the civil, military, and naval life of those who molded this
            government between the close of the American Revolution and the
            close of the War of 1812, from 1784 to 1815 inclusive, and the
            maintaining, at National Headquarters in Washington D.C., a museum
            and library of memorabilia of the 1784-1815 period.
          </Paragraph>

          <Paragraph className="mb-4 indent-8">
            Women age eighteen and older are eligible for membership providing
            that they can satisfactorily document their lineal descent from an
            ancestor who rendered military, naval or civil service between the
            years of 1784 and 1815, inclusive. Please click on the "Membership"
            link below for some additional qualifying forms of service. Further
            information on our organization and its membership application
            requirements can also be found on our National Society's{" "}
            <ExternalLink href="http://www.usdaughters1812.org/">
              website
            </ExternalLink>
            .
          </Paragraph>

          <div className="bg-slate-100 rounded border-slate-400 border p-4">
            <div className="gap-8 flex flex-col max-w-[600px] mx-auto">
              <div className="text-center">
                <h3 className="text-l font-semibold mb-2">State Motto</h3>
                <p>“A Beacon Shining Bright for Service”</p>
              </div>

              <div className="text-center">
                <h3 className="text-l font-semibold mb-2">State Scripture</h3>
                <Paragraph>
                  “I am the light of the world. Whoever follows me will not walk
                  in darkness, but will have the light of life.”
                  <br />
                  John 8:12
                </Paragraph>
              </div>

              <div>
                <h3 className="text-l font-semibold text-center mb-2">
                  State Projects
                </h3>
                <ul className="flex flex-col list-disc list-outside gap-1 pl-4">
                  <li>
                    <span>
                      Continue to locate and mark graves of 1812 Veterans
                    </span>
                  </li>

                  <li>
                    <span>
                      Honor Real Daughters and Real Granddaughters by locating
                      and marking graves
                    </span>
                  </li>

                  <li>
                    <span>
                      Dedicate a black granite memorial bench at The Ohio
                      Veterans Memorial Park for the Ohio Society
                    </span>
                  </li>

                  <li>
                    <span>
                      Promote Star Spangled Banner Day - September 14th
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </Section>

        <Section id={ROUTES.OFFICERS.key} label={ROUTES.OFFICERS.label}>
          <Officers />
        </Section>

        <Section id={ROUTES.COMMITTEES.key} label={ROUTES.COMMITTEES.label}>
          <Committees />
        </Section>

        <Section id={ROUTES.CHAPTERS.key} label={ROUTES.CHAPTERS.label}>
          <Chapters />
        </Section>

        <Section id="more" className="flex flex-col items-center ">
          <More />
        </Section>

        <Footer />
      </Main>
    </Container>
  );
}

/*==============================================
                  COMPONENTS
==============================================*/

function Header({ className }: { className?: string }) {
  return (
    <header
      className={classNames("grid", className)}
      style={{
        gridTemplateAreas: `
          'logo heading'
          'logo subHeading'
        `,
        gridTemplateColumns: "auto auto",
        gridTemplateRows: "auto auto",
      }}
    >
      <img
        src={logoSrc}
        alt="naval anchor contained by a star"
        className="place-self-end w-64 md:w-96"
        style={{
          gridArea: "logo",
        }}
      />
    </header>
  );
}

function Section({
  children,
  id,
  label,
  className,
}: {
  children: React.ReactNode;
  id?: string;
  label?: string;
  className?: string;
}) {
  return (
    <section
      id={id}
      className={className}
      style={{
        paddingTop: HEADER_HEIGHT,
      }}
    >
      {label && (
        <h2 className="text-2xl font-bold mb-4 text-center">{label}</h2>
      )}

      {children}
    </section>
  );
}

function Footer() {
  return (
    <footer>
      <Paragraph className="p-4 text-xs text-center">
        Web hyperlinks to non-U.S.D. of 1812 sites are not the responsibility of
        the N.S.U.S.D. 1812, the state societies, or individual 1812 chapters.
      </Paragraph>
    </footer>
  );
}

export default Home;
