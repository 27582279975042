import { useQuery } from "@tanstack/react-query";

import { ROUTES } from "src/constants";
import DataService from "src/services/data";
import List, { ListItem } from "src/components/list";
import { useListColumns } from "src/hooks/common";

function Committees() {
  const { data } = useQuery(
    [ROUTES.COMMITTEES.key],
    DataService.getCommittees,
    {
      suspense: true,
    }
  );

  const columns = useListColumns();

  return (
    <List columns={columns}>
      {data!.map((committee) => (
        <ListItem key={committee.name}>{committee.name}</ListItem>
      ))}
    </List>
  );
}

export default Committees;
