import { useMediaQuery } from "@react-hook/media-query";

function useBreakpoints() {
  const isSmallDevice = useMediaQuery("only screen and (max-width : 768px)");

  const isMediumDevice = useMediaQuery(
    "only screen and (min-width : 769px) and (max-width : 992px)"
  );

  const isLargeDevice = useMediaQuery(
    "only screen and (min-width : 993px) and (max-width : 1200px)"
  );

  const isExtraLargeDevice = useMediaQuery(
    "only screen and (min-width : 1201px)"
  );

  return {
    isSmallDevice,
    isMediumDevice,
    isLargeDevice,
    isExtraLargeDevice,
  };
}

function useListColumns() {
  const { isMediumDevice, isLargeDevice, isExtraLargeDevice } =
    useBreakpoints();

  if (isMediumDevice) {
    return 2;
  }

  if (isLargeDevice || isExtraLargeDevice) {
    return 3;
  }

  return 1;
}

export { useBreakpoints, useListColumns };
