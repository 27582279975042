import classNames from "classnames";
import { range } from "lodash";

export interface ListProps {
  children: React.ReactNode;
  className?: string;
  columns?: number;
}

function List({ children, className, columns = 1 }: ListProps) {
  const gridTemplateColumns = range(columns)
    .map(() => "1fr")
    .join(" ");

  return (
    <ul
      className={classNames("grid", className)}
      style={{
        gridTemplateColumns,
      }}
    >
      {children}
    </ul>
  );
}

export interface ListItemProps {
  children: React.ReactNode;
  className?: string;
}

function ListItem({ children, className }: ListItemProps) {
  return (
    <li
      className={classNames(
        "text-center p-2 text-lg m-2 box-border bg-slate-100 rounded flex justify-center items-center border border-slate-400",
        className
      )}
    >
      {children}
    </li>
  );
}

export default List;

export { ListItem };
