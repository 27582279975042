import classNames from "classnames";
import { Nav } from "src/components/layout";
import { ROUTES, HEADER_HEIGHT } from "src/constants";
import { Route, Layout } from "src/types";

const NAV_LIST_ITEMS = [
  ROUTES.HOME,
  ROUTES.OFFICERS,
  ROUTES.COMMITTEES,
  ROUTES.CHAPTERS,
];

export interface NavListProps {
  layout?: Layout;
  onPressItem?: () => void;
}

function NavList({ layout = "horizontal", onPressItem }: NavListProps) {
  const isHorizontal = layout === "horizontal";

  return (
    <div className={classNames("flex", isHorizontal ? "flex-row" : "flex-col")}>
      {NAV_LIST_ITEMS.map((item) => (
        <NavListItem key={item.label} route={item} onPressItem={onPressItem} />
      ))}
    </div>
  );
}

function NavListItem({
  route,
  onPressItem,
}: {
  route: Route;
  onPressItem?: () => void;
}) {
  return (
    <a href={route.href} className="p-4" onClick={onPressItem}>
      {route.label}
    </a>
  );
}

export default NavList;
