import List, { ListItem } from "src/components/list";
import footerImgSrc from "src/assets/1812-sm.gif";
import { ExternalLink, Paragraph } from "src/components/common";
import { useBreakpoints } from "src/hooks/common";
import classNames from "classnames";
import { ArrowTopRightOnSquareIcon } from "@heroicons/react/24/outline";

const items = [
  // REMOVED: Url is broken
  // {
  //   label: "Index of Ohio Grave Locations, War of 1812 Veterans",
  //   href: "http://www.ohiodaughters1812.org/graveindex/default.php",
  // },

  {
    label: "Fort Meigs",
    href: "https://www.fortmeigs.org/",
  },

  {
    label: "Perry's Victory & International Peace Memorial ",
    href: "https://www.nps.gov/pevi/index.htm",
  },

  {
    label: "The Society of the War of 1812 in Ohio",
    href: "https://ohiosociety1812.wordpress.com/",
  },
];

function More() {
  const { isSmallDevice, isMediumDevice, isLargeDevice, isExtraLargeDevice } =
    useBreakpoints();

  const compact = isSmallDevice;

  const style = compact
    ? {
        gridTemplateAreas: `
          'img'
          'sponsorship'
          'links'
        `,
        gridTemplateColumns: "1fr",
        gridTemplateRows: "auto auto auto",
      }
    : {
        gridTemplateAreas: `
          'img links'
          'sponsorship links'
        `,
        gridTemplateColumns: "1fr 1fr",
        gridTemplateRows: "auto auto",
      };

  return (
    <div
      className={classNames(
        "grid items-center justify-items-center w-full",
        compact ? "gap-8" : "gap-2"
      )}
      style={style}
    >
      <img
        src={footerImgSrc}
        alt=""
        className="w-[134px] h-[125px]"
        style={{
          gridArea: "img",
        }}
      />

      <div
        className="w-full"
        style={{
          gridArea: "links",
        }}
      >
        <h4 className="text-lg font-semibold mb-2 text-center">Links</h4>

        <List columns={1}>
          {items.map((item) => (
            <ListItem key={item.label} className="p-0">
              <ExternalLink
                href={item.href}
                className="text-sm flex flex-1 items-center justify-center p-2 h-full gap-2"
              >
                {item.label}

                <ArrowTopRightOnSquareIcon className="w-4 " />
              </ExternalLink>
            </ListItem>
          ))}
        </List>
      </div>

      <div
        style={{
          gridArea: "sponsorship",
        }}
      >
        <Paragraph className={classNames("text-center", !compact && "w-96")}>
          The Ohio Society is a gold-level sponsor of the{" "}
          <ExternalLink href="https://www.ngsgenealogy.org/preserve-the-pensions/">
            Preserve the Pensions Project
          </ExternalLink>
        </Paragraph>
      </div>
    </div>
  );
}

export default More;
