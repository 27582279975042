import { useQuery } from "@tanstack/react-query";
import { EnvelopeIcon } from "@heroicons/react/24/outline";

import { ROUTES } from "src/constants";
import DataService from "src/services/data";
import List, { ListItem } from "src/components/list";
import { Mailto } from "src/components/common";
import { useListColumns } from "src/hooks/common";
import { Fragment } from "react";

function Officers() {
  const { data } = useQuery([ROUTES.OFFICERS.key], DataService.getOfficers, {
    suspense: true,
  });

  const columns = useListColumns();

  return (
    <List columns={columns}>
      {data!.map((officer) =>
        officer.name ? (
          <ListItem key={officer.title} className="flex flex-col">
            <div className="flex flex-row items-center gap-2">
              {officer.name}

              {officer.email && (
                <Mailto email={officer.email}>
                  <EnvelopeIcon className="w-6 box-border p-1" />
                </Mailto>
              )}
            </div>

            <div className="text-sm">{officer.title}</div>
          </ListItem>
        ) : (
          <Fragment key={officer.title} />
        )
      )}
    </List>
  );
}

export default Officers;
