import {
  ViewColumnsIcon,
  UserIcon,
  DocumentIcon,
  StarIcon,
} from "@heroicons/react/24/outline";
import classNames from "classnames";

import { SUPPLEMENTS } from "src/constants";
import { Supplement, Layout } from "src/types";
import { ExternalLink } from "src/components/common";

export interface SupplementsProps {
  layout?: Layout;
  compactItems?: boolean;
  onPressItem?: () => void;
}

function Supplements({ layout, compactItems, onPressItem }: SupplementsProps) {
  const icons = {
    [SUPPLEMENTS.MEMBERSHIP.key]: <UserIcon />,
    [SUPPLEMENTS.BROCHURE.key]: <ViewColumnsIcon />,
    // [SUPPLEMENTS.FLYER.key]: <DocumentIcon />,
    [SUPPLEMENTS.NATIONAL_SOCIETY.key]: <StarIcon />,
  };

  const supplements = [
    SUPPLEMENTS.MEMBERSHIP,
    SUPPLEMENTS.BROCHURE,
    // SUPPLEMENTS.FLYER,
    SUPPLEMENTS.NATIONAL_SOCIETY,
  ];

  const isHorizontal = layout === "horizontal";

  return (
    <div
      className={classNames(
        "flex gap-4",
        isHorizontal ? "flex-row justify-center" : "flex-col sticky top-[56px]"
      )}
    >
      {supplements.map((supplement) => (
        <SupplementItem
          key={supplement.label}
          supplement={supplement}
          icon={icons[supplement.key]}
          compact={isHorizontal || compactItems}
          onPressItem={onPressItem}
        />
      ))}
    </div>
  );
}

function SupplementItem({
  supplement,
  icon,
  compact,
  onPressItem,
}: {
  supplement: Supplement;
  icon: React.ReactNode;
  compact?: boolean;
  onPressItem?: () => void;
}) {
  return (
    <ExternalLink
      href={supplement.href}
      className={classNames(
        "flex items-center p-2",
        compact ? "flex-row gap-1" : "flex-col w-24"
      )}
      onPressItem={onPressItem}
    >
      <div className={classNames(compact ? "w-4" : "w-8")}>{icon}</div>

      <span className="text-xs text-center">{supplement.label}</span>
    </ExternalLink>
  );
}

export default Supplements;
