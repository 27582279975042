import { Chapter, Committee, Officer } from "src/types";
import { parseCSV } from "src/utils/csv";

class DataService {
  static fetchCSV = async (pathToCSV: string) => {
    const getCSVData = async () => {
      const response = await fetch(pathToCSV);

      const reader = response.body?.getReader();
      return reader?.read();
    };

    const decodeCSVData = (result?: ReadableStreamReadResult<Uint8Array>) => {
      const decoder = new TextDecoder("utf-8");
      return decoder.decode(result?.value);
    };

    const data = await getCSVData();
    const csv = decodeCSVData(data);

    return csv;
  };

  static getDataFromCSV = async <T>(pathToCSV?: string) => {
    if (pathToCSV) {
      const csv = await DataService.fetchCSV(pathToCSV);
      return parseCSV<T>(csv);
    }

    return [];
  };

  static getOfficers = () =>
    DataService.getDataFromCSV<Officer>(
      process.env.REACT_APP_PATH_TO_OFFICERS_CSV
    );

  static getCommittees = () =>
    DataService.getDataFromCSV<Committee>(
      process.env.REACT_APP_PATH_TO_COMMITTEES_CSV
    );

  static getChapters = () =>
    DataService.getDataFromCSV<Chapter>(
      process.env.REACT_APP_PATH_TO_CHAPTERS_CSV
    );
}

export default DataService;
