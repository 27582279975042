import { Suspense } from "react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

import "./App.css";
import Root from "./components/root";

const queryClient = new QueryClient();

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <Suspense>
        <Root />
      </Suspense>
    </QueryClientProvider>
  );
}

export default App;
