import { Route, Supplement } from "./types";

const ROUTES: Record<string, Route> = {
  HOME: {
    key: "HOME",
    label: "Home",
    href: "/#HOME",
  },

  OFFICERS: {
    key: "OFFICERS",
    label: "Officers",
    href: "/#OFFICERS",
  },

  COMMITTEES: {
    key: "COMMITTEES",
    label: "Committees",
    href: "/#COMMITTEES",
  },

  CHAPTERS: {
    key: "CHAPTERS",
    label: "Chapters",
    href: "/#CHAPTERS",
  },
};

const SUPPLEMENTS: Record<string, Supplement> = {
  MEMBERSHIP: {
    key: "MEMBERSHIP",
    label: "Membership",
    href: "http://www.usdaughters1812.org/membership.html",
  },

  BROCHURE: {
    key: "BROCHURE",
    label: "Brochure",
    href: "/documents/OhioSoc1812brochure.pdf",
  },

  FLYER: {
    key: "FLYER",
    label: "Flyer",
    href: "/documents/ohiosoc1812infoflyer.pdf",
  },

  NATIONAL_SOCIETY: {
    key: "NATIONAL_SOCIETY",
    label: "National Society",
    href: "http://www.usdaughters1812.org/",
  },
};

const HEADER_HEIGHT = 56;

export { ROUTES, SUPPLEMENTS, HEADER_HEIGHT };
