import classNames from "classnames";

function Container({
  children,
  compact,
}: {
  children: React.ReactNode;
  compact?: boolean;
}) {
  const styles = !compact
    ? {
        gridTemplateAreas: `
            'space nav'
            'aside main'
          `,
        gridTemplateRows: "auto 1fr auto",
        gridTemplateColumns: "auto 1fr",
      }
    : {
        gridTemplateAreas: `
          'nav'
          'aside'
          'main'
        `,
        gridTemplateRows: "auto auto 1fr auto",
        gridTemplateColumns: "1fr",
      };

  return (
    <main className="grid" style={styles}>
      {children}
    </main>
  );
}

function Main({ children }: { children: React.ReactNode }) {
  return (
    <div
      className="max-w-4xl mx-auto flex flex-col gap-32 px-4"
      style={{
        gridArea: "main",
      }}
    >
      {children}
    </div>
  );
}

function Nav({
  children,
  height,
  className,
}: {
  children: React.ReactNode;
  height: number;
  className?: string;
}) {
  return (
    <nav
      className="sticky top-0  h-0"
      style={{
        gridArea: "nav",
      }}
    >
      <div
        className={classNames("bg-white flex flex-row gap-1", className)}
        style={{
          height,
        }}
      >
        {children}
      </div>
    </nav>
  );
}

function Aside({ children }: { children: React.ReactNode }) {
  return (
    <aside
      style={{
        gridArea: "aside",
      }}
    >
      {children}
    </aside>
  );
}

export { Container, Main, Nav, Aside };
