import * as React from "react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";

import { HEADER_HEIGHT } from "src/constants";
import { createPortal } from "react-dom";
import NavList from "src/components/nav-list";
import Supplements from "./supplements";

function MobileNav() {
  const [isOpen, setIsOpen] = React.useState(false);

  const toggleVisibility = () => setIsOpen(!isOpen);

  React.useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [isOpen]);

  return (
    <div>
      <button
        onClick={toggleVisibility}
        title={isOpen ? "close navigation" : "open navigation"}
        className="inline p-4"
      >
        <Bars3Icon className="w-6" />
      </button>

      {isOpen && (
        <BodyPortal>
          <SideNav onRequestClose={() => setIsOpen(false)} />
        </BodyPortal>
      )}
    </div>
  );
}

function SideNav({ onRequestClose }: { onRequestClose: () => void }) {
  return (
    <>
      <button
        onClick={onRequestClose}
        title="close navigation"
        className="fixed top-0 left-0 bottom-0 right-0 bg-black opacity-30"
      />

      <div className="flex flex-col min-w-[220px] fixed top-0 left-0 bg-white h-full shadow overflow-auto">
        <div
          style={{
            height: HEADER_HEIGHT,
          }}
        >
          <button
            onClick={onRequestClose}
            title="close navigation"
            className="inline p-4"
          >
            <XMarkIcon className="w-6" />
          </button>
        </div>

        <NavList layout="vertical" onPressItem={onRequestClose} />

        <div className="px-2 py-4">
          <div className="h-[1px] bg-slate-400" />
        </div>

        <Supplements
          layout="vertical"
          compactItems
          onPressItem={onRequestClose}
        />
      </div>
    </>
  );
}

function BodyPortal({ children }: { children: React.ReactNode }) {
  const [parentElement] = React.useState(document.body);

  return createPortal(children, parentElement);
}

export default MobileNav;
