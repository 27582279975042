import { useQuery } from "@tanstack/react-query";
import { GlobeAltIcon, EnvelopeIcon } from "@heroicons/react/24/outline";

import { ROUTES } from "src/constants";
import DataService from "src/services/data";
import List, { ListItem } from "src/components/list";
import { ExternalLink, Mailto } from "./common";
import { useListColumns } from "src/hooks/common";

function Chapters() {
  const { data } = useQuery([ROUTES.CHAPTERS.key], DataService.getChapters, {
    suspense: true,
  });

  const columns = useListColumns();

  return (
    <List columns={columns}>
      {data!.map((chapter) => (
        <ListItem
          key={chapter.name}
          className="gap-2 flex flex-row items-center"
        >
          <div className="flex-1">{chapter.name}</div>

          {chapter.href && (
            <ExternalLink href={chapter.href}>
              <GlobeAltIcon className="w-6 box-border p-1" />
            </ExternalLink>
          )}

          <Mailto email={chapter.email}>
            <EnvelopeIcon className="w-6 box-border p-1" />
          </Mailto>
        </ListItem>
      ))}
    </List>
  );
}

export default Chapters;
